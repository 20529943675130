import React from 'react';
import styled, { keyframes } from 'styled-components';

const PageTitle = React.memo(({ title, titleKana, color = '#fff' }) => {
    return (
        <Sdiv color={color} className="area_page_title">
            <h1 className="page_title">
                <span className="page_title--en">{title}</span>
                <span className="page_title--kana">{titleKana}</span>
            </h1>
        </Sdiv>
    );
});
const borderStretches = keyframes`
    from {
        width: 0px;
    }
    to {
        width: 180%;
    }
`;
const Sdiv = styled.div`
    user-select: none;
    position: relative;
    margin-top: 50px;
    z-index: 2;
    display: inline-block;
    color: ${({ color }) => color};
    .page_title--en {
        width: calc(100% + 2px);
        font-size: 3.8rem;
        margin-bottom: 10px;
        display: block;
    }
    .page_title--kana {
        display: block;
        font-size: 1.3rem;
        text-align: center;
        margin-bottom: 10px;
    }
    &::after {
        content: '';
        display: inline-block;
        animation: ${borderStretches} 0.7s 1.2s linear forwards;
        height: 2px;
        background-color: ${({ color }) => color};
    }
    @media (min-width: 960px) {
        .page_title--en {
            font-size: 5rem;
        }
        .page_title--kana {
            font-size: 1.5rem;
        }
    }
`;
export default PageTitle;
