import React from 'react';
import BackToTopBtn from '../../atoms/button/backtotopbtn/BackToTopBtn';
import PageTitle from '../../atoms/pagetitle/PageTitle';
import ContactTextArea from '../../organisms/contacttextarea/ContactTextArea';

const ContactArea = React.memo(() => {
    return (
        <div className="area_contact">
            <PageTitle title="Contact" titleKana="コンタクト" color="#333" />
            <ContactTextArea />
            <BackToTopBtn btnRole="back to top" />
        </div>
    );
});
export default ContactArea;
