import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import HbBtn from '../components/atoms/button/hbbtn/HbBtn';
import TransitionAnime from '../components/atoms/transitionanime/TransitionAnime';
import Contact from '../components/pages/contact/Contact';
import Gallery from '../components/pages/gallery/Gallery';
import Home from '../components/pages/home/Home';
import Order from '../components/pages/order/Order';
import GlbMenu from '../components/templates/glbmenu/GlbMenu';
import HbMenuArea from '../components/templates/hbmenuarea/HbMenuArea';
import Loading from '../providers/loading/Loading';
import Transition from '../providers/transition/Transition';
import ScrollTop from '../scrolltop/ScrollTop';

function Router() {
    const isDesktop = useMediaQuery({ query: '(min-width: 960px)' });
    const location = useLocation();
    const setIsTransition = useSetRecoilState(Transition);
    const setLoading = useSetRecoilState(Loading);
    useEffect(() => {
        setIsTransition(false);
        setTimeout(() => {
            setIsTransition(true);
            setLoading(false);
        }, 3000);
    }, []);
    const header = () => {
        switch (location.pathname) {
            case '/order':
                return isDesktop ? <GlbMenu color="#fff" /> : <HbBtn />;
            default:
                return isDesktop ? <GlbMenu /> : <HbBtn color="#333" />;
        }
    };
    return (
        <>
            <ScrollTop />
            <TransitionAnime />
            <header className="header_1">{header()}</header>
            <HbMenuArea />
            <main>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/order" element={<Order />} />
                    <Route path="/gallery" element={<Gallery />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/*" element={<Home />} />
                </Routes>
            </main>
        </>
    );
}

export default Router;
