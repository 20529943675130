import React from 'react';
import { useRecoilValue } from 'recoil';
import styled, { css, keyframes } from 'styled-components';
import Loading from '../../../providers/loading/Loading';

function SiteTitle() {
    const loading = useRecoilValue(Loading);
    return (
        <Sdiv loading={loading} className="site_title">
            <span className="area_site_title_name">
                <span className="site_title_name">mami</span>
            </span>
            <span className="area_site_title_cat">
                <span className="site_title_cat">calligraphy</span>
            </span>
        </Sdiv>
    );
}

const fadeIn = keyframes`
    from {
width: 0;
    }
    to {
width: 105%;
    }
`;
const Sdiv = styled.div`
    font-size: 4rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: 0.8s;
    color: ${({ loading }) => (loading ? '#fff' : '#333')};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (min-width: 960px) {
        font-size: 5.5rem;
    }
    .area_site_title_name {
        display: block;
        margin-bottom: 25px;
        .site_title_name {
            width: 105%;
            display: block;
            overflow: hidden;
            white-space: nowrap;
            ${({ loading }) =>
                loading &&
                css`
                    width: 0;
                    animation: 0.8s 1s ${fadeIn} forwards linear;
                `}
        }
    }
    .area_site_title_cat {
        display: inline-block;
        .site_title_cat {
            height: 6.5rem;
            width: 105%;
            overflow: hidden;
            display: block;
            white-space: nowrap;
            ${({ loading }) =>
                loading &&
                css`
                    width: 0;
                    animation: 0.8s 2s ${fadeIn} forwards linear;
                `}
    }
`;
export default SiteTitle;
