import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import styled, { keyframes } from 'styled-components';
import Transition from '../../../../providers/transition/Transition';
import circlelight from '../../../../../images/circlelight.svg';

const OrderBtnText = React.memo(({ btnRole }) => {
    const contactLink = useNavigate();
    const setIsTransition = useSetRecoilState(Transition);
    const transition = () => {
        setIsTransition(false);
        setTimeout(() => {
            contactLink('/contact');
        }, 800);
    };
    return (
        <Sdiv circle={circlelight} onClick={transition} className="area_order_btn_contact">
            <div className="order_btn_contact">
                <button type="button" className="btn_ico_contact">
                    {btnRole}
                </button>
            </div>
        </Sdiv>
    );
});

const arrowAnimation = keyframes`
    0% {
        right: 0;
    }
    50% {
        right: -5px;
    }
    100% {
        right: 0;
    }
`;
const rotation = keyframes`
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
`;
const Sdiv = styled.div`
    width: 100%;
    height: 215px;
    position: relative;
    .order_btn_contact {
        z-index: 2;
        position: absolute;
        bottom: 20px;
        right: 0;
        cursor: pointer;
        user-select: none;
        font-size: 1.3rem;
        width: 165px;
        height: 165px;
        display: flex;
        align-items: center;
        justify-content: center;
        &::before {
            content: '';
            background-image: url(${({ circle }) => circle});
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            width: 100%;
            height: 100%;
        }
        &:hover {
            &::before {
                animation: 30s linear infinite ${rotation};
            }
        }
        .btn_ico_contact {
            color: #fff;
        }
    }
    @media (min-width: 960px) {
        height: 300px;

        .order_btn_contact {
            /* bottom: 20px; */
            /* right: 60px; */
            font-size: 1.8rem;
            width: 250px;
            height: 250px;
            .btn_ico_contact {
                position: relative;
                display: inline-flex;
                align-items: center;
                padding-right: 35px;
                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    animation: ${arrowAnimation} 2s ease-in-out infinite alternate;
                }
                &::before {
                    width: 25px;
                    height: 2px;
                    background-color: #fff;
                }
                &::after {
                    width: 10px;
                    height: 10px;
                    border-right: 2px solid #fff;
                    border-top: 2px solid #fff;
                    transform: rotate(45deg);
                }
            }
        }
    }
`;

export default OrderBtnText;
