import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import OrderDisplayChange from '../../../providers/orderdisplaychange/OrderDisplayChange';
import SizeChange from '../../../providers/sizechange/SizeChange';
import Transition from '../../../providers/transition/Transition';

function GlbNavList({ color = '#333' }) {
    const orderContentTitle = 'オーダー品について';
    const orderContentText = <p className="order_content">{orderContentTitle}</p>;
    const [sizeIsChange, setSizeIsChange] = useRecoilState(SizeChange);
    const setOrderIsChange = useSetRecoilState(OrderDisplayChange);
    const link = useNavigate();
    const setIsTransition = useSetRecoilState(Transition);
    const location = useLocation();
    const transition = (path) => {
        if (sizeIsChange) {
            setSizeIsChange(false);
            setOrderIsChange(orderContentText);
        }
        if (location.pathname === path) {
            return;
        }
        setIsTransition(false);
        setTimeout(() => {
            link(path);
        }, 800);
    };
    return (
        <Sul className="area_nav_list" color={color}>
            <li className="nav_list">
                <a onClick={() => transition('/order')} className="list_link">
                    order
                </a>
            </li>
            <li className="nav_list">
                <a onClick={() => transition('/gallery')} className="list_link">
                    gallery
                </a>
            </li>
            <li className="nav_list">
                <a onClick={() => transition('/contact')} className="list_link">
                    contact
                </a>
            </li>
        </Sul>
    );
}
const Sul = styled.ul`
    user-select: none;
    display: flex;
    align-items: center;
    margin-right: 45px;
    font-size: 2rem;
    .nav_list {
        .list_link {
            color: ${({ color }) => color};
        }
    }
    .nav_list + .nav_list {
        margin-left: 45px;
    }
`;

export default GlbNavList;
