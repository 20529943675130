import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import Transition from '../../../providers/transition/Transition';
import topview from '../../../../images/topview.mp4';

function TopView() {
    const orderLink = useNavigate();
    const [isTransition, setIsTransition] = useRecoilState(Transition);
    const v = useRef();
    useEffect(() => {
        if (!isTransition) {
            setIsTransition(true);
        }
        return () => {};
    }, []);
    const transition = () => {
        setIsTransition(false);
        setTimeout(() => {
            orderLink('/order');
        }, 800);
    };
    const stop = () => {
        v.current.pause();
    };
    return (
        <Sdiv onClick={transition} className="top_view">
            <video ref={v} onEnded={stop} playsInline src={topview} muted autoPlay />
        </Sdiv>
    );
}
const Sdiv = styled.div`
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 calc(50% - 50vw);
    video {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
`;
export default TopView;
