import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled, { css, keyframes } from 'styled-components';
import Transition from '../../../../providers/transition/Transition';
import circlelightsvg from '../../../../../images/circlelight.svg';
import circledarksvg from '../../../../../images/circledark.svg';

const BackToTopBtn = React.memo(({ btnRole, color = 'dark' }) => {
    const homeLink = useNavigate();
    const [isTransition, setIsTransition] = useRecoilState(Transition);
    const transition = () => {
        setIsTransition(false);
        setTimeout(() => {
            homeLink('/');
        }, 800);
    };
    return (
        <Sdiv
            color={color}
            isTransition={isTransition}
            circle={color === 'light' ? circlelightsvg : circledarksvg}
            onClick={transition}
            className="area_back_btn_contact"
        >
            <a href="#contact" className="btn_ico_back">
                {btnRole}
            </a>
        </Sdiv>
    );
});
const rotation = keyframes`
    from {
        transform:rotate(0);
    }
    to {
        transform:rotate(-360deg);
    }
`;
const Sdiv = styled.div`
    z-index: 2;
    position: fixed;
    bottom: 20px;
    cursor: pointer;
    user-select: none;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    ${({ isTransition }) =>
        !isTransition &&
        css`
            pointer-events: none;
        `}
    &::before {
        content: '';
        background-image: url(${({ circle }) => circle});
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        width: 100%;
        height: 100%;
    }
    &:hover {
        &::before {
            animation: 30s linear infinite ${rotation};
        }
    }
    .btn_ico_back {
        width: auto;
        height: auto;
        color: ${({ color }) => (color === 'light' ? '#fff' : '#333')};
    }
    @media (min-width: 960px) {
        font-size: 1.8rem;
        width: 150px;
        height: 150px;
    }
`;

export default BackToTopBtn;
