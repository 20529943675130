import React from 'react';

function InstagramSvg({ color = '#333' }) {
    return (
        <div className="area_instagram">
            <a
                href="https://www.instagram.com/mami_calligraphy331"
                target="_blank"
                rel="noreferrer"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40.68"
                    viewBox="0 0 40 40.68"
                >
                    <g id="instagram" transform="translate(-4.281)">
                        <path
                            d="M32.785,0H15.778a11.5,11.5,0,0,0-11.5,11.5V29.184a11.5,11.5,0,0,0,11.5,11.5H32.784a11.5,11.5,0,0,0,11.5-11.5V11.5A11.5,11.5,0,0,0,32.785,0ZM8.363,11.5a7.423,7.423,0,0,1,7.415-7.415H32.784A7.423,7.423,0,0,1,40.2,11.5V29.184A7.423,7.423,0,0,1,32.784,36.6H15.778a7.423,7.423,0,0,1-7.415-7.415Z"
                            transform="translate(0)"
                            fill={color}
                        />
                        <path
                            d="M133.3,142.36a9.107,9.107,0,1,0-9.154-9.107A9.141,9.141,0,0,0,133.3,142.36Zm0-15.376a6.269,6.269,0,1,1-6.3,6.269A6.292,6.292,0,0,1,133.3,126.984Z"
                            transform="translate(-109.022 -112.912)"
                            fill={color}
                        />
                        <ellipse
                            cx="2.673"
                            cy="2.673"
                            rx="2.673"
                            ry="2.673"
                            transform="translate(32.192 7.08)"
                            fill={color}
                        />
                    </g>
                </svg>
            </a>
        </div>
    );
}

export default InstagramSvg;
