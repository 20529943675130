import React from 'react';
import styled from 'styled-components';

function Button({ btnRole }) {
    return (
        <Sdiv className="area_btn_form">
            <a
                href="https://www.instagram.com/mami_calligraphy331"
                target="_blank"
                rel="noreferrer"
            >
                {btnRole}
            </a>
        </Sdiv>
    );
}

const Sdiv = styled.div`
    cursor: pointer;
    user-select: none;
    width: 300px;
    height: 80px;
    font-size: 2rem;
    border: 2px #333 solid;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    a {
        line-height: 80px;
        width: 100%;
    }
`;
export default Button;
