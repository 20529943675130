import React from 'react';
import styled, { keyframes } from 'styled-components';
import GalleryCard from '../../atoms/gallerycard/GalleryCard';
import naming from '../../../../images/naming/naming_1.jpg';
import namingbrother1 from '../../../../images/namingbrother/namingbrother_1.jpg';
import namingbrother2 from '../../../../images/namingbrother/namingbrother_2.jpg';
import namingbrother3 from '../../../../images/namingbrother/namingbrother_3.jpg';
import namingmessage1 from '../../../../images/namingmessage/namingmessage_1.jpg';
import namingmessage2 from '../../../../images/namingmessage/namingmessage_2.jpg';
import designproduction1 from '../../../../images/designproduction/designproduction_1.jpg';
import designproduction2 from '../../../../images/designproduction/designproduction_2.jpg';
import designproduction3 from '../../../../images/designproduction/designproduction_3.jpg';

const GalleryCardArea = React.memo(() => {
    return (
        <Sdiv className="area_gallery_card">
            <ul className="gallery_card_list">
                <GalleryCard img={naming} caption="「命名書」" />
                <GalleryCard img={namingbrother1} caption="「ご兄弟お名前書」" />
                <GalleryCard img={namingbrother2} caption="「ご兄弟お名前書」" />
                <GalleryCard img={namingbrother3} caption="「ご兄弟お名前書」" />
                <GalleryCard img={namingmessage1} caption="「メッセージお名前書」" />
                <GalleryCard img={namingmessage2} caption="「メッセージお名前書」" />
                <GalleryCard img={designproduction1} caption="「デザイン書道作品」" />
                <GalleryCard img={designproduction2} caption="「デザイン書道作品」" />
                <GalleryCard img={designproduction3} caption="「デザイン書道作品」" />
            </ul>
        </Sdiv>
    );
});
const scaleIn = keyframes`
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
`;

const Sdiv = styled.div`
    .gallery_card_list {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
        padding-top: 100px;
        padding-bottom: 100px;
        margin: auto;
        justify-items: center;
        align-items: center;
        .gallery_card {
            animation: ${scaleIn} 1s forwards;
        }
        .gallery_card:nth-child(1) {
            animation-delay: 0.2s;
        }
        .gallery_card:nth-child(2) {
            animation-delay: 0.3s;
        }
        .gallery_card:nth-child(3) {
            animation-delay: 0.4s;
        }
        .gallery_card:nth-child(4) {
            animation-delay: 0.5s;
        }
        .gallery_card:nth-child(5) {
            animation-delay: 0.6s;
        }
        .gallery_card:nth-child(6) {
            animation-delay: 0.7s;
        }
        .gallery_card:nth-child(7) {
            animation-delay: 0.8s;
        }
        .gallery_card:nth-child(8) {
            animation-delay: 0.9s;
        }
        .gallery_card:nth-child(9) {
            animation-delay: 1s;
        }
    }
    @media (min-width: 520px) {
        .gallery_card_list {
            grid-template-columns: repeat(3, 1fr);
            width: 85%;
        }
    }
    @media (min-width: 960px) {
        .gallery_card_list {
            width: 60%;
        }
    }
`;

export default GalleryCardArea;
