import React from 'react';
import { useRecoilValue } from 'recoil';
import styled, { keyframes } from 'styled-components';
import Loading from '../../../providers/loading/Loading';

function TopTitle() {
    const loading = useRecoilValue(Loading);
    return (
        <Sh1 className="top_title">
            <span className={loading ? 'area_title' : 'area_title slideAnimeUpDown'}>
                <span className={loading ? 'top_title_catch' : 'top_title_catch slideAnimeDownUp'}>
                    <span className="catch">「想いを込めて名付けたお名前を</span>
                    <span className="catch_br">世界にひとつだけのインテリア書に仕立てます。」</span>
                </span>
            </span>
            <span
                className={
                    loading
                        ? 'area_title area_title_author'
                        : ' area_title area_title_author fade_in'
                }
            >
                <span className="top_title_author">
                    <span className="author">命名書作家</span>
                    <span className="author_name">難波　麻美</span>
                </span>
            </span>
        </Sh1>
    );
}
const slideTextY100 = keyframes`
from {
    transform: translateY(100%);
    opacity: 0;
}
to {
    transform: translateY(0);
    opacity: 1;
}
`;

const slideTextYminus100 = keyframes`
from {
    transform: translateY(-100%);
    opacity: 0;
}
to {
    transform: translateY(0);
    opacity: 1;
}
`;
const fadeIn = keyframes`
from {
    opacity: 0;
}
to {
    opacity: 1;
}
`;

const Sh1 = styled.h1`
    color: #333;
    line-height: 3;
    font-weight: bold;
    letter-spacing: 0.2em;
    writing-mode: vertical-rl;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    .area_title {
        white-space: nowrap;
        overflow: hidden;
        display: block;
        opacity: 0;
    }
    .area_title_author {
        text-align: end;
    }
    .top_title_catch {
        opacity: 0;
        display: block;
        margin-left: 10px;
        font-size: 2.5vh;
        .catch {
            display: block;
        }
        .catch_br {
            display: block;
        }
    }
    .top_title_author {
        width: fit-content;
        margin-bottom: 30px;

        font-size: 2vh;

        .author {
            margin-bottom: 10px;
        }
    }
    @media (min-width: 520px) {
        right: 15%;
        transform: translate(0, -50%);
    }
    @media (min-width: 960px) {
        top: 60%;
    }
    .slideAnimeDownUp {
        animation: ${slideTextY100} 2s 1s forwards;
        opacity: 0;
    }
    .slideAnimeUpDown {
        animation: ${slideTextYminus100} 2s 1s forwards;
        opacity: 0;
    }
    .fade_in {
        animation: ${fadeIn} 2s 2.5s forwards;
        opacity: 0;
    }
`;
export default TopTitle;
