import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import Transition from '../../../providers/transition/Transition';
import TopTitle from '../../atoms/toptitle/TopTitle';

const TopViewTitle = React.memo(() => {
    const orderLink = useNavigate();
    const setIsTransition = useSetRecoilState(Transition);
    const transition = () => {
        setIsTransition(false);
        setTimeout(() => {
            orderLink('/order');
        }, 800);
    };
    return (
        <Sdiv onClick={transition} className="area_site_title">
            <TopTitle />
        </Sdiv>
    );
});

const Sdiv = styled.div`
    user-select: none;
    position: relative;
    height: 100%;
    width: 100%;
`;
export default TopViewTitle;
