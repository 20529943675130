import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import DisplayHbMenu from '../../../providers/displayhbmenu/DisplayHbMenu';
import HbNavArea from '../../molecules/hbnavarea/HbNavArea';

function HbMenuArea() {
    const [hbIsMenu, setHbIsMenu] = useRecoilState(DisplayHbMenu);
    const hbMenu = () => {
        setHbIsMenu(false);
    };
    return (
        <Sdiv
            onClick={hbMenu}
            id="hb_menu"
            aria-expanded={hbIsMenu}
            aria-hidden={!hbIsMenu}
            className="hb_menu"
        >
            <CSSTransition
                classNames="modal"
                in={hbIsMenu}
                timeout={{
                    enter: 0,
                    exit: 700,
                }}
                unmountOnExit
            >
                <div className="hb_menu_area">
                    <HbNavArea />
                </div>
            </CSSTransition>
        </Sdiv>
    );
}
const Sdiv = styled.div`
    z-index: 998;
    transition: 0.7s;
    .hb_menu_area {
        transition: 0.7s;
        background-color: #fff;
        height: 100vh;
        height: 100dvh;
        width: 100vw;
        position: fixed;
        opacity: 0;
        z-index: 998;
    }

    .modal-enter-active {
        opacity: 0;
    }
    .modal-enter-done {
        opacity: 1;
        transition: 0.7s;
    }
    .modal-exit-active {
        opacity: 0;
    }
`;

export default HbMenuArea;
