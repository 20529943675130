import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled, { keyframes } from 'styled-components';
import ColorImg from '../../../providers/colorimg/ColorImg';
import Transition from '../../../providers/transition/Transition';
import OrderArea from '../../templates/orderarea/OrderArea';
import colorimg from '../../../../images/colorimg.jpg';

function Order() {
    const setIsTransition = useSetRecoilState(Transition);
    setIsTransition(true);
    const [colorIsImg, setColorIsImg] = useRecoilState(ColorImg);
    const colorImg = () => {
        setColorIsImg(false);
    };
    const invalid = (e) => {
        e.stopPropagation();
    };
    return (
        <article className="area_article_order">
            <div className="container">
                <OrderArea />
            </div>
            <AnimatePresence>
                {colorIsImg && (
                    <SMdiv
                        id="color_img"
                        aria-expanded={colorIsImg}
                        aria-hidden={!colorIsImg}
                        key="colorImg"
                        exit={{ opacity: 0 }}
                        onClick={colorImg}
                        className="area_color_img"
                    >
                        <div key="colorImg" onClick={invalid} className="color_img">
                            <img src={colorimg} />
                        </div>
                    </SMdiv>
                )}
            </AnimatePresence>
        </article>
    );
}
const fadeIn = keyframes`
    from {
    opacity: 0;
    }
    to {
    opacity: 1;
    }
`;
const fadeInView = keyframes`
    from {
        background-color: rgba(51,51,51,0);
    }
    to {
        background-color: rgba(51,51,51,0.8);
    }
`;
const SMdiv = styled(motion.div)`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(51, 51, 51, 0.8);
    animation: ${fadeInView} 1s;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    .color_img {
        animation: ${fadeIn} 1s;
        width: 80vmin;
        height: auto;
        img {
            max-width: 80%;
            max-height: 80%;
            width: 100%;
            height: 100%;
            margin: auto;
        }
    }
`;

export default Order;
