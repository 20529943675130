import React from 'react';
import { useRecoilValue } from 'recoil';
import styled, { css, keyframes } from 'styled-components';
import naming from '../../../../images/naming/naming_1.jpg';
import Loading from '../../../providers/loading/Loading';
import ColorImgBtn from '../../atoms/button/colorimgbtn/ColorImgBtn';

function OrderAboutContent() {
    const loading = useRecoilValue(Loading);
    return (
        <Ssection loading={loading} className="area_section_about">
            <div className="area_order_about_content">
                <div className="order_head line">
                    <h2 className="order_title">オーダー品について</h2>
                </div>
                <div className="order_body">
                    <div className="order_img">
                        <img src={naming} alt="オーダー品サンプル画像" />
                    </div>
                    <div className="area_order_text">
                        <div className="order_text">
                            <p className="order_price">命名書 ¥7500税込</p>
                            <p className="order_frame">
                                【ホワイトフレーム】サイズ25x25
                                <br />
                                ・プラスチック製フロントパネル使用
                                <br />
                                *卓上でも壁掛けでも使用可能
                            </p>
                            <p className="order_paper">
                                【台紙】
                                <br />
                                。阿波和紙 朝落水紙 使用
                                <br />
                                *お好きなカラーをお選び下さい。
                                <br />
                                <ColorImgBtn />
                            </p>
                            <p className="order_instagram">
                                Instagramメッセージにてオーダー受付ております。
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Ssection>
    );
}

const line = keyframes`
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
`;
const Ssection = styled.section`
    color: #fff;
    margin: auto;
    width: 80%;
    padding-top: 100px;
    margin-bottom: 100px;
    .area_order_about_content {
        .order_head {
            margin-bottom: 80px;
            .order_title {
                text-align: center;
                font-size: 2rem;
            }
        }
        .line {
            display: block;
            position: relative;
            padding-bottom: 30px;
        }
        .line::after {
            background: #fff;
            content: '';
            display: block;
            height: 1px;
            width: 0;
            transform: translateX(-50%);
            ${({ loading }) =>
                !loading &&
                css`
                    animation: 1.5s 0.8s ${line} cubic-bezier(0.22, 1, 0.36, 1) forwards;
                `}
            position: absolute;
            bottom: 0;
            left: 50%;
        }
        .order_body {
            .order_img {
                width: 100%;
                margin-bottom: 70px;
            }
            .area_order_text {
                .order_text {
                    line-height: 1.5;
                    font-size: 1.5rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    .order_price {
                        margin-bottom: 15px;
                    }
                    .order_frame {
                        margin-bottom: 15px;
                    }
                    .order_paper {
                        margin-bottom: 15px;
                    }
                    .order_instagram {
                    }
                }
            }
        }
    }
    @media (min-width: 520px) {
        padding-top: 150px;
        .area_order_about_content {
            .order_head {
                .order_title {
                    font-size: 3rem;
                }
            }
            .area_order_text {
                .order_text {
                    font-size: 1.8rem;
                }
            }
        }
    }
    @media (min-width: 960px) {
        margin-bottom: 200px;
        .area_order_about_content {
            .order_head {
                margin-bottom: 150px;
            }
            .order_body {
                display: flex;
                justify-content: space-around;
                .order_img {
                    width: 45%;
                    min-width: 400px;
                    margin-bottom: 0;
                }
                .area_order_text {
                    display: flex;
                    align-items: center;
                    .order_text {
                        padding-left: 30px;
                        white-space: nowrap;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        height: 80%;
                        max-height: 350px;
                        .order_price {
                            margin-bottom: 0;
                        }
                        .order_frame {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
`;

export default OrderAboutContent;
