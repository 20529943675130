import React from 'react';
import styled, { keyframes } from 'styled-components';

function OrderDesignContent() {
    return (
        <Sdiv className="area_order_content">
            <div className="order_content">
                <h3 className="order_content_title">デザイン書道</h3>
                <p className="order_content_text">
                    「想いを込めて名付けたお名前を
                    <br />
                    世界にひとつだけのインテリア書に
                    <br />
                    仕立てます」
                    <br />
                    ＊命名書＊出産祝い＊お名前書
                </p>
                <ul className="order_content_text order_content_list">
                    <li>命名書</li>
                    <li>ご兄弟のお名前</li>
                    <li>記念日へのメッセージ</li>
                    <span>お受けしております。</span>
                </ul>
            </div>
        </Sdiv>
    );
}
const fadeIn = keyframes`
    from {
    opacity: 0;
    }
    to {
    opacity: 1;
    }
`;

const Sdiv = styled.div`
    padding: 40px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    .order_content {
        line-height: 1.5;
        color: #fff;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .order_content_title {
            font-size: 2rem;
            margin-bottom: 15px;
        }
        .order_content_text {
            font-size: 1.5rem;

        }
        .order_content_list {
            margin-top: 15px;
            list-style-type: none;
            li {
                &::before {
                    content: '・';
                }
            }
        }

    @media (min-width: 960px) {
        .order_content {
            .order_content_title {
                font-size: 2.5rem;
            }
            .order_content_text {
                font-size: 2rem;
            }
        }
    }
`;

export default OrderDesignContent;
