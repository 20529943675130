import React from 'react';
import { atom } from 'recoil';

const orderContentTitle = 'オーダー品について';
const orderContent = <p className="order_content">{orderContentTitle}</p>;

const OrderDisplayChange = atom({
    key: 'OrderDisplayChange',
    default: orderContent,
});

export default OrderDisplayChange;
