import React from 'react';
import styled from 'styled-components';
import HbNav from '../../atoms/hbnav/HbNav';
import InstagramSvg from '../../atoms/svg/instagramsvg/InstagramSvg';

function HbNavArea() {
    return (
        <Snav className="area_hb_menu">
            <div className="area_hb_nav">
                <HbNav />
                <InstagramSvg color="#333" />
            </div>
        </Snav>
    );
}
const Snav = styled.nav`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    .area_hb_nav {
        max-height: 400px;
        min-height: 350px;
        height: 35%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }
`;

export default HbNavArea;
