import { motion } from 'framer-motion';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { useRecoilValue } from 'recoil';
import styled, { css, keyframes } from 'styled-components';
import Loading from '../../../providers/loading/Loading';
import Transition from '../../../providers/transition/Transition';
import SiteTitle from '../sitetitle/SiteTitle';

const TransitionAnime = React.memo(() => {
    const isTransition = useRecoilValue(Transition);
    const loading = useRecoilValue(Loading);
    return (
        <SMdiv isTransition={isTransition} className="transition_area">
            <CSSTransition
                classNames="loading"
                in={loading}
                timeout={{
                    enter: 0,
                    exit: 1200,
                }}
                unmountOnExit
            >
                <SiteTitle />
            </CSSTransition>
        </SMdiv>
    );
});
const transitionUp = keyframes`
    0% {
        height: 100vh;
        height: 100dvh;
    }
    100% {
        height: 0;
    }
`;
const transitionDown = keyframes`
    0% {
        height: 0;
    }
    100% {
        height: 100vh;
        height: 100dvh;
    }
`;
const SMdiv = styled(motion.div)`
    width: 100vw;
    height: 0;
    background-color: #333;
    position: fixed;
    top: 0;
    z-index: 9999;
    ${({ isTransition }) =>
        isTransition
            ? css`
                  animation: ${transitionUp} 0.7s forwards;
              `
            : css`
                  animation: ${transitionDown} 0.7s forwards;
              `}

    .loading-enter-active {
        opacity: 0;
    }
    .loading-enter-done {
        opacity: 1;
    }
    .loading-exit-active {
        opacity: 0;
    }
`;
export default TransitionAnime;
