import React from 'react';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import ColorImg from '../../../../providers/colorimg/ColorImg';

function ColorImgBtn({ color = '#fff' }) {
    const setColorIsImg = useSetRecoilState(ColorImg);
    const colorImg = () => {
        setColorIsImg(true);
    };
    return (
        <Sspan color={color} className="area_color_img_button">
            <button
                aria-controls="#color_img"
                aria-label="カラーイメージを展開"
                onClick={colorImg}
                type="button"
            >
                台紙カラーイメージ
            </button>
        </Sspan>
    );
}
const Sspan = styled.span`
    cursor: pointer;
    user-select: none;
    height: 50px;
    font-size: 1.5rem;
    border: 2px ${({ color }) => color} solid;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    button {
        color: ${({ color }) => color};
        display: block;
        height: 100%;
        width: 100%;
        margin: 0;
    }
    @media (min-width: 960px) {
        font-size: 1.5rem;
        width: 250px;
    }
`;

export default ColorImgBtn;
