import React from 'react';
import styled, { keyframes } from 'styled-components';
import TopView from '../../atoms/topview/TopView';
import TopViewTitle from '../../molecules/topviewtitle/TopViewTitle';

function HomeArea() {
    return (
        <Sdiv className="area_top_view">
            <TopView />
            <TopViewTitle />
        </Sdiv>
    );
}
const events = keyframes`
    0% {
        pointer-events: none;
    }
    100% {
        pointer-events: auto;
    }
`;
const Sdiv = styled.div`
    pointer-events: none;
    animation: ${events} 1s 0.1s forwards;
    position: relative;
    height: 100dvh;
`;
export default HomeArea;
