import React from 'react';
import BackToTopBtn from '../../atoms/button/backtotopbtn/BackToTopBtn';
import PageTitle from '../../atoms/pagetitle/PageTitle';
import GalleryCardArea from '../../molecules/gallerycardarea/GalleryCardArea';

const GalleryArea = React.memo(() => {
    return (
        <div className="gallery_area">
            <PageTitle title="Gallery" titleKana="ギャラリー" color="#333" />
            <GalleryCardArea />
            <BackToTopBtn btnRole="back to top" />
        </div>
    );
});

export default GalleryArea;
