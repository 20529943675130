import React from 'react';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import ImgView from '../../../providers/imgview/ImgView';

const GalleryCard = React.memo(({ img, caption }) => {
    const setImgIsView = useSetRecoilState(ImgView);
    const imgView = () => {
        setImgIsView([{ click: true, link: img }]);
    };
    return (
        <Sli
            aria-controls="#gallery_img"
            aria-label="ギャラリー画像拡大"
            onClick={imgView}
            className="gallery_card"
        >
            <div className="gallery_content">
                <div className="gallery_img">
                    <img src={img} />
                </div>
                <div className="gallery_caption">
                    <p className="gallery_caption_text">{caption}</p>
                </div>
            </div>
        </Sli>
    );
});

const Sli = styled.li`
    position: relative;
    background-color: #fff;
    width: 100%;
    transform: scale(0);
    transition: 0.5s;

    &::before {
        content: '';
        display: block;
        padding-top: 130%;
    }
    &:hover {
        box-shadow: 0 40px 25px rgba(0, 0, 0, 0.5);
    }

    .gallery_content {
        padding: 5px 5px 0 5px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        .gallery_img {
            height: 75%;
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        .gallery_caption {
            user-select: none;
            height: 25%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #333;
            margin: auto;
            white-space: nowrap;
        }
    }
    @media (max-width: 519px) {
        .gallery_caption {
            font-size: 1.3rem;
        }
    }
    @media (min-width: 520px) {
        .gallery_caption {
            font-size: max(1vw, 1.3rem);
        }
    }
    @media (min-width: 960px) {
        .gallery_caption {
            font-size: min(1vw, 2.5rem);
        }
    }
`;
export default GalleryCard;
