import React from 'react';
import { useRecoilState } from 'recoil';
import styled, { css } from 'styled-components';
import DisplayHbMenu from '../../../../providers/displayhbmenu/DisplayHbMenu';

function HbBtn({ color = '#fff' }) {
    const [hbIsMenu, setHbIsMenu] = useRecoilState(DisplayHbMenu);
    const hbMenu = () => {
        setHbIsMenu(!hbIsMenu);
    };
    return (
        <Sdiv hbIsMenu={hbIsMenu} color={color} className="container">
            <div
                aria-controls="#hb_menu"
                aria-label="メニューを展開"
                onClick={hbMenu}
                className="area_hamburger_btn"
            >
                <button type="button" className="hamburger_btn">
                    <span className="hamburger_line" />
                </button>
            </div>
        </Sdiv>
    );
}

const Sdiv = styled.div`
    height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    .area_hamburger_btn {
        cursor: pointer;
        user-select: none;
        height: 100%;
        width: 100%;
        position: relative;
        .hamburger_btn {
            bottom: 0;
            right: 0;
            width: 50px;
            height: 50px;
            position: absolute;
            .hamburger_line {
                width: 26px;
                display: block;
                position: relative;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                background-color: ${({ color }) => color};
                height: 1px;
                transition: 0.4s;
                ${({ hbIsMenu }) =>
                    hbIsMenu &&
                    css`
                        background-color: transparent;
                    `}
                &::before,
                &::after {
                    transition: 0.4s;
                    content: '';
                    position: absolute;
                    background-color: ${({ color }) => color};
                    display: block;
                    width: 100%;
                    height: 100%;
                    ${({ hbIsMenu }) =>
                        hbIsMenu &&
                        css`
                            background-color: #333;
                        `}
                }
                &::before {
                    top: -6px;
                    ${({ hbIsMenu }) =>
                        hbIsMenu &&
                        css`
                            transform: rotate(-315deg);
                            top: 0;
                        `}
                }
                &::after {
                    top: 6px;
                    ${({ hbIsMenu }) =>
                        hbIsMenu &&
                        css`
                            transform: rotate(315deg);
                            top: 0;
                        `}
                }
            }
        }
    }
`;
export default HbBtn;
