import React from 'react';
import styled from 'styled-components';
import Button from '../../atoms/button/button/Button';
import ContactTexe from '../../molecules/contacttext/ContactText';

function ContactTextArea() {
    return (
        <Sdiv className="area_contact_text_btn">
            <ContactTexe />
            <Button btnRole="OPEN INSTAGRAM DM" />
        </Sdiv>
    );
}
const Sdiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
    width: 80%;
    margin: auto;
`;
export default ContactTextArea;
