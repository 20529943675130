import React from 'react';
import styled from 'styled-components';
import GlbNavList from '../../atoms/glbnavlist/GlbNavList';
import InstagramSvg from '../../atoms/svg/instagramsvg/InstagramSvg';

function GlbNavArea({ color = '#333' }) {
    return (
        <Snav className="area_nav_menu">
            <GlbNavList color={color} />
            <InstagramSvg color={color} />
        </Snav>
    );
}
const Snav = styled.nav`
    display: flex;
    align-items: center;
`;
export default GlbNavArea;
