import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import OrderDesignContent from '../../atoms/orderdesigncontent/OrderDesignContent';
import useScroll from '../../../custom/usescroll/useScroll';

function OrderDesign() {
    const [strokeCheck, setStrokeCheck] = useState(false);
    const scrollPosition = useScroll();
    const stroke = useRef(null);
    let strokeY = '';
    useEffect(() => {
        strokeY = stroke.current.getBoundingClientRect().y + 1000;
    }, []);
    useEffect(() => {
        if (strokeCheck) {
            return;
        }
        if (scrollPosition > strokeY) {
            setStrokeCheck(true);
        }
    });
    return (
        <Ssection className="area_section_orderdesign">
            <div ref={stroke} className={strokeCheck ? 'stroke is-animated' : 'stroke'}>
                <div className="border top" />
                <div className="border right" />
                <div className="border bottom" />
                <div className="border left" />
                <OrderDesignContent />
            </div>
        </Ssection>
    );
}

const strokewidth = keyframes`
    0% {
      width: 0;
      opacity: 1;
    }
    100% {
      width: calc(100% - 50px);
      opacity: 1;
    }
`;
const strokeheight = keyframes`
    0% {
      height: 0;
      opacity: 1;
    }
    100% {
      height: calc(100% - 50px);
      opacity: 1;
    }
  `;

const Ssection = styled.section`
    width: 100%;
    .stroke {
        margin: auto;
        width: 100%;
        height: 100%;
        position: relative;
        text-align: center;
        .border {
            content: '';
            position: absolute;
            opacity: 0;
        }
        .top,
        .bottom {
            width: calc(100% - 50px);
        }
        .top {
            border-top: 3px solid #fff;
            right: 0;
            top: 0;
        }
        .bottom {
            border-bottom: 3px solid #fff;
            left: 0;
            bottom: 0;
        }
        .right,
        .left {
            height: calc(100% - 50px);
        }
        .right {
            border-right: 3px solid #fff;
            right: 0;
            top: 0;
        }
        .left {
            border-left: 3px solid #fff;
            left: 0;
            bottom: 0;
        }
        &.is-animated {
            .top,
            .bottom {
                animation: ${strokewidth} 1.8s 0.5s cubic-bezier(0.22, 1, 0.36, 1) forwards;
            }
            .right,
            .left {
                animation: ${strokeheight} 1.8s 0.5s cubic-bezier(0.22, 1, 0.36, 1) forwards;
            }
        }

        @media (min-width: 520px) {
        }
        @media (min-width: 960px) {
            width: 60%;
        }
    }
`;
export default OrderDesign;
