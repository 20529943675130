import React from 'react';
import styled from 'styled-components';
import GlbNavArea from '../../molecules/glbnavarea/GlbNavArea';

function GlbMenu({ color = '#333' }) {
    return (
        <Sdiv className="container">
            <div className="area_pc_nav">
                <GlbNavArea color={color} />
            </div>
        </Sdiv>
    );
}
const Sdiv = styled.div`
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    margin: auto;
    height: 100px;
    display: flex;
    justify-content: end;
    align-items: flex-end;
`;
export default GlbMenu;
