import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import DisplayHbMenu from '../../../providers/displayhbmenu/DisplayHbMenu';
import OrderDisplayChange from '../../../providers/orderdisplaychange/OrderDisplayChange';
import SizeChange from '../../../providers/sizechange/SizeChange';
import Transition from '../../../providers/transition/Transition';

function HbNav() {
    const setHbIsMenu = useSetRecoilState(DisplayHbMenu);
    const orderContentTitle = 'オーダー品について';
    const orderContentText = <p className="order_content">{orderContentTitle}</p>;
    const [sizeIsChange, setSizeIsChange] = useRecoilState(SizeChange);
    const setOrderIsChange = useSetRecoilState(OrderDisplayChange);
    const link = useNavigate();
    const setIsTransition = useSetRecoilState(Transition);
    const location = useLocation();
    const transition = (path) => {
        if (sizeIsChange) {
            setSizeIsChange(false);
            setOrderIsChange(orderContentText);
        }
        if (location.pathname === path) {
            setHbIsMenu(false);
            return;
        }
        setIsTransition(false);
        setTimeout(() => {
            link(path);
            setHbIsMenu(false);
        }, 800);
    };
    return (
        <Sul className="area_hb_list">
            <li className="hb_item">
                <a onClick={() => transition('/order')}>order</a>
            </li>
            <li className="hb_item">
                <a onClick={() => transition('/gallery')}>gallery</a>
            </li>
            <li className="hb_item">
                <a onClick={() => transition('/contact')}>contact</a>
            </li>
        </Sul>
    );
}
const Sul = styled.ul`
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 65%;
`;

export default HbNav;
