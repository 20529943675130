import React from 'react';
import styled from 'styled-components';
import ColorImgBtn from '../../atoms/button/colorimgbtn/ColorImgBtn';

function ContactText() {
    return (
        <Sdiv className="area_content_text">
            <p className="contact_text">
                発注までの流れ
                <br />
                <br />
                ①ご注文頂きましたら内容についてお伺いする詳細メールをお送りいたしますのでご返信をお願いいたします。
                <br />
                <br />
                ②ご返信いただいた内容を確認し制作に入ります。
                <br />
                <br />
                ③商品が完成致しましたら、お客様に画像付きメッセージをお送り致します。
                <br />
                内容をご確認頂き、３営業日以内に確認のご返信を頂きますようお願い致します。
                <br />
                <br />
                ④お客様から確認のメールを頂き次第、発送の手配をさせて頂きます。
                <br />
                発送の手配が完了致しましたら最終のご連絡を差し上げますのでご確認ください。
                <br />
                <br />
                ⑤商品がお客様のお手元に届き次第、商品のご確認をお願い致します。
                <br />
                破損や汚れ等、商品に不備がございました場合、お手数ですが１週間以内にご連絡をお願い致します。
                <br />
                万が一納品から１週間以上経過した後にご連絡頂きます場合、恐れ入りますが商品を再度ご購入頂く形となりますので、必ず納品後すぐに商品をご確認ください。
                <br />
                <br />
                <br />
                商品について
                <br />
                <br />
                ・ハンドメイド作品のため、見本画像と全く同じものは制作出来かねます。
                <br />
                手作りにご理解のある方のみご注文ください。
                <br />
                ・文字の崩し方や装飾は、全体のバランスによって異なりますため、ご指定いただけません。
                <br />
                ・商品完成後お客様に画像をお送りしご確認頂いておりますが、デザインが気に入らない等の理由でキャンセルはお受けしておりません。
                <br />
                ・画像と実物とで色の見え方に多少の違いがある場合がございます。
                <br />
                ・誤字脱字についてはお客様からお送り頂いた詳細を元に当店にて細心の注意を払い確認致しますが、お手元に届き次第ご確認をお願いいたします。
                <br />
                万が一発送後に誤字脱字が発覚した場合、最優先で商品を再発送させて頂き、また送料につきましても当店で負担させて頂きます。
                <br />
                万が一お客様にお送り頂いた詳細自体に誤字脱字がありました場合は、再度ご購入頂く形となり、ご返金や交換は出来かねます。
                <br />
                ・商品は、引っ掻いたり擦ったり濡らしてしまいますと、インクが削れたり滲んでしまう可能性があります。
                <br />
                またお客様によって商品に不良が生じてしまった場合当店では責任を負うことが出来かねます。
                <br />
                <br />
                <br />
                額縁について
                <br />
                <br />
                ホワイトフレーム
                <br />
                ブラックフレーム
                <br />
                サイズ25×25
                <br />
                ・プラスチック製フロントパネル使用
                <br />
                ＊卓上でも壁掛けでも使用可能
                <br />
                【台紙】
                <br />
                ・阿波和紙 朝落水紙 使用
                <br />
                ＊お好きなカラーをお選び下さい。
                <br />
                <br />
                <ColorImgBtn color="#333" />
                <br />
                <br />
                <br />
                返品、交換、キャンセルについて
                <br />
                <br />
                ・キャンセルは一切受け付けておりません。
                <br />
                お客様から詳細メールのご返信や完成品のご確認メールのご返信を頂けなかった場合、制作することや発送することは出来かねますが、キャンセル扱いにはならずご返金も出来かねます。
                <br />
                <br />
                ※書風、書体は完全お任せとなります。
                <br />
                誤字脱字以外のイメージと違うなどによる書き直しの一切は致しかねます。
                <br />
                デザインや向きの変更も不可。
                <br />
                <br />
                <br />
                ご注文はInstagramのDMでお受けしております。
            </p>
        </Sdiv>
    );
}

const Sdiv = styled.div`
    font-size: 1.5rem;
    line-height: 1.5;
    margin: 100px 0;
    @media (min-width: 960px) {
        font-size: 1.8rem;
    }
`;

export default ContactText;
