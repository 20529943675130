import React from 'react';
import Hidden from '../../../globalstyles/overflow/hidden/Hidden';
import HomeArea from '../../templates/homearea/HomeArea';

function Home() {
    return (
        <article className="area_article_home">
            <Hidden />
            <div className="container">
                <HomeArea />
            </div>
        </article>
    );
}

export default Home;
