import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled, { keyframes } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import BackgroundColorLight from '../../../globalstyles/backgroundcolor/backgroundcolorlight/BackgroundColorLight';
import ImgView from '../../../providers/imgview/ImgView';
import Transition from '../../../providers/transition/Transition';
import GalleryArea from '../../templates/galleryarea/GalleryArea';

function Gallery() {
    const setIsTransition = useSetRecoilState(Transition);
    setIsTransition(true);
    const [imgIsView, setImgIsView] = useRecoilState(ImgView);
    const closeImgView = () => {
        setImgIsView([{ click: false, link: '' }]);
    };
    const invalid = (e) => {
        e.stopPropagation();
    };
    return (
        <article className="area_article_gallery">
            <overflowHidden />
            <BackgroundColorLight />
            <Sdiv className="container">
                <GalleryArea />
            </Sdiv>
            <AnimatePresence>
                {imgIsView.map(
                    (i) =>
                        i.click && (
                            <section className="area_section_gallery">
                                <SMdiv
                                    id="gallery_img"
                                    aria-expanded={i.click}
                                    aria-hidden={!i.click}
                                    exit={{ opacity: 0 }}
                                    key={uuidv4()}
                                    onClick={closeImgView}
                                    className="area_img_view"
                                >
                                    <div key={uuidv4()} onClick={invalid} className="img_view">
                                        <img src={i.link} />
                                    </div>
                                </SMdiv>
                            </section>
                        )
                )}
            </AnimatePresence>
        </article>
    );
}
const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
    `;
const fadeInView = keyframes`
        from {
        background-color: rgba(51,51,51,0);
        }
        to {
            background-color: rgba(51,51,51,0.8);
        }
`;
const Sdiv = styled.div`
    opacity: 0;
    animation: ${fadeIn} 0.1s 0.5s forwards;
`;
const SMdiv = styled(motion.div)`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(51, 51, 51, 0.8);
    animation: ${fadeInView} 1s;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    .img_view {
        animation: ${fadeIn} 1s;
        width: 80vmin;
        height: auto;
        img {
            max-width: 80%;
            max-height: 80%;
            width: 100%;
            height: 100%;
            margin: auto;
        }
    }
`;
export default Gallery;
