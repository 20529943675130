import React from 'react';
import styled, { keyframes } from 'styled-components';
import BackgroundColorDark from '../../../globalstyles/backgroundcolor/backgroundcolordark/BackgroundColorDark';
import BackToTopBtn from '../../atoms/button/backtotopbtn/BackToTopBtn';
import OrderBtnText from '../../atoms/button/orderbtntext/OrderBtnText';
import PageTitle from '../../atoms/pagetitle/PageTitle';
import OrderAboutContent from '../../molecules/orderaboutcontent/OrderAboutContent';
import OrderDesign from '../../molecules/orderdesign/OrderDesign';

function OrderArea() {
    return (
        <Sdiv className="area_order">
            <BackgroundColorDark />
            <PageTitle title="Order" titleKana="オーダー" />
            <OrderAboutContent />
            <OrderDesign />
            <BackToTopBtn btnRole="back to top" color="light" />
            <OrderBtnText btnRole="OPEN CONTACT FORM" />
        </Sdiv>
    );
}

const events = keyframes`
    0% {
        pointer-events: none;
    }
    100% {
        pointer-events: auto;
    }
`;
const Sdiv = styled.div`
    animation: ${events} 1s 0.1s forwards;
    width: 100%;
    height: 100%;
`;

export default OrderArea;
