import { atom } from 'recoil';

const ImgView = atom({
    key: 'ImgView',
    default: [
        {
            click: false,
            link: '',
        },
    ],
});

export default ImgView;
